import React, { Component } from "react"

import "../styles/BluePagePraktiker.css"
import {Container, Form, Row, Col, Button} from "react-bootstrap"
import {Lock} from "react-feather"

import {EMAIL_REGEX, HOST_URL, PASSWORD_RESET_URL} from "../constants";

export class PasswordResetPraktiker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            responseStatus: ':(',
            responseStatusText: 'Da ist was schiefgegangen.'
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    formValid() {
        console.log('form is being validated')

        let emailError = ""

        if(!this.state.email) {
            emailError = "Bitte eine E-Mail-Adresse eingeben."
        }

        else if(!EMAIL_REGEX.test(this.state.email)) {
            emailError = "Bitte eine gültige E-Mail-Adresse eingeben."
        }

        if(emailError) {

            console.log('setting state')

            this.setState({
                emailError: emailError
            })
            return false
        }
        return true
    }


    handleSubmit(event) {
        event.preventDefault()
        console.log('login is being submitted')

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: this.state.email,
            })
        }

        console.log(requestOptions)

        if(this.formValid()) {
            fetch(HOST_URL + PASSWORD_RESET_URL, requestOptions)
                .then((response) => {
                    if(!response.ok) {
                        this.setState({
                                responseStatus: response.status,
                                responseStatusText: response.statusText
                            }
                        )
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    response.json()
                })

                .then((data) => {
                    console.log('password reset successful', data)
                    this.props.history.push({
                        pathname: "/professional/password-info",
                        state: {email: this.state.email}
                    })
                })

                .catch((error) => {
                    console.log(error)
                    this.props.history.push({
                        pathname: "/professional/error",
                        state: {
                            responseStatus: this.state.responseStatus,
                            responseStatusText: this.state.responseStatusText
                        }
                    })
                })
        }
    }

    handleChange(event) {
        const input = event.target
        const value = event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;

        this.setState( {
            [event.target.name]: value
        })

        console.log(input.name + ' was changed')
    }

    render() {
        return (
            <Container fluid className="BluePagePraktiker">
                <Row className="fill-page">
                    <Col className="icon-custom-position" md={4}>
                        <Lock className="icon-custom" />
                    </Col>

                    <Col className="center-contents-row" md={4}>
                        <Form className="form-small" onSubmit={this.handleSubmit}>
                            <h1 className="page-title">NEUES PASSWORT</h1>
                            <Form.Group as={Row} controlId="formResetPwEmail">
                                <Form.Control
                                    name="email"
                                    className={this.state.emailError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="email"
                                    placeholder="E-MAIL-ADRESSE"
                                    value={this.state.email}
                                    onChange={this.handleChange}/>
                                <div className="error-msg">
                                    {this.state.emailError}
                                </div>
                            </Form.Group>

                            <Button
                                type="submit"
                                variant="outline-light"
                                onClick={this.handleSubmit}>
                                PASSWORT ANFORDERN
                            </Button>
                        </Form>
                    </Col>

                    <Col className="center-contents-row" md={4}>
                    </Col>

                </Row>
            </Container>
        )
    }
}

export default PasswordResetPraktiker