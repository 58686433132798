import React, {Component} from "react"

import "../styles/BluePagePraktiker.css"
import {Container, Row, Col, Button} from "react-bootstrap"

export class SignupInfoPraktiker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: ''//this.props.location.state.email
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.history.push({
            pathname: "/professional/login",
            state: {email: this.state.email}
        })
    }

    render() {
        return (
            <Container fluid className="BluePagePraktiker">
                <Row className="fill-page" >
                    <Col className="center-contents-column">
                        <Row className="info-msg">
                            Wir haben dir ein E-Mail mit dem Link zum Ändern deines Passwortes geschickt. <br/>Bitte ändere dein Passwort und logge dich danach damit ein.
                        </Row>
                        <Row>
                            <Button variant="outline-light"
                                    type="submit"
                                    onClick={this.handleClick}>
                                EINLOGGEN
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default SignupInfoPraktiker