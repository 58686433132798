import React, {useState} from "react"
import {BrowserRouter as Router, Route, Switch, useLocation} from "react-router-dom"

import "./App.css"
import "./styles/radialMenu.css"

import BlueHeaderSplit from "./components/BlueHeaderSplit"

import BlueHeader from "./components/BlueHeader"
import BlueFooter from "./components/BlueFooter"

import BlueHeaderPraktiker from "./components/BlueHeaderPraktiker"
import BlueFooterPraktiker from "./components/BlueFooterPraktiker"
import SplitPage from "./components/SplitPage"
import StartPage from "./components/StartPage"
import SignupForm from "./components/SignupForm"
import PasswordPicker from "./components/PasswordPicker"
import VoucherForm from "./components/VoucherForm"
import LoginForm from "./components/LoginForm"
import Mybooklink from "./components/Mybooklink"
import ErrorPage from "./components/ErrorPage"
import SignupInfo from "./components/SignupInfo"
import PasswordReset from "./components/PasswordReset"
import PasswordInfo from "./components/PasswordInfo"
import GreyHeader from "./components/GreyHeader"
import SideDrawer from "./components/SideDrawer"

import PrivateRoute from "./PrivateRoute"
import {AuthContext} from "./authentication/auth"
import Backdrop from "./components/Backdrop"
import PasswordGenerated from "./components/PasswordGenerated";

import ErrorPagePraktiker from "./components/ErrorPagePraktiker";
import PasswordGeneratedPraktiker from "./components/PasswordGeneratedPraktiker";
import StartPagePraktiker from "./components/StartPagePraktiker";
import SignupFormPraktiker from "./components/SignupFormPraktiker";
import {PasswordPickerPraktiker} from "./components/PasswordPickerPraktiker";
import PasswordResetPraktiker from "./components/PasswordResetPraktiker";
import PasswordInfoPraktiker from "./components/PasswordInfoPraktiker";
import SignupInfoPraktiker from "./components/SignupInfoPraktiker";
import VoucherFormPraktiker from "./components/VoucherFormPraktiker";
import LoginFormPraktiker from "./components/LoginFormPraktiker";
import MybooklinkPraktiker from "./components/MybooklinkPraktiker";

import UserprofileModal from "./components/UserprofileModal";

function App() {
    const existingTokens = JSON.parse(localStorage.getItem('tokens'))
    const [authTokens, setAuthTokens] = useState(existingTokens)
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

    //const history = useHistory();

    function setTokens(token) {
        localStorage.setItem('tokens', JSON.stringify(token))
        setAuthTokens(token)
    }


    function toggleSideDrawer() {
        if (sideDrawerOpen) {
            setSideDrawerOpen(false)
        }
        if (!sideDrawerOpen) {
            setSideDrawerOpen(true)
        }
    }

    window.setSideDrawerOpen = setSideDrawerOpen;

    window.toggleSideDrawer = toggleSideDrawer;


    if (JSON.parse(localStorage.getItem('tokens'))) {
        console.log(document.location.href);
        if (document.location.href.indexOf("/facultas/mybooklink") == -1) {
            document.location.href = "/facultas/mybooklink";
        } else {
            if (window.fname == "Willkommen in deinem Mybooklink!") {
                setTimeout(function () {
                    window.loadFirstBook(authTokens)
                }, 250);
            }
        }
    }

    function backdropClickHandler() {
        setSideDrawerOpen(false)
    }

    function Layout({children}) {

        let location = useLocation()
        let sideDrawer
        let backdrop

        if (sideDrawerOpen) {
            sideDrawer = <SideDrawer drawerClickHandler={toggleSideDrawer}/>
            backdrop = <Backdrop backdropClickHandler={backdropClickHandler}/>
        }

        if (location.pathname === '/mybooklink') {
            return (
                <>
                    <GreyHeader drawerClickHandler={toggleSideDrawer}/>
                    {sideDrawer}
                    {backdrop}
                    {children}
                </>
            )
        } else if (location.pathname === '/professional/mybooklink') {
            return (
                <>
                    <GreyHeader drawerClickHandler={toggleSideDrawer}/>
                    {sideDrawer}
                    {backdrop}
                    {children}
                </>
            )
        } else if (document.location.href.indexOf("professional") > -1) {
            return (
                <>
                    <BlueHeaderPraktiker/>
                    {children}
                    <BlueFooterPraktiker/>
                </>
            )
        }
        else if (document.location.href.indexOf("students") > -1) {
        return (
            <>
                <BlueHeader/>
                {children}
                <BlueFooter/>
            </>
        )
    } else {
            return (
                <>
                    <BlueHeaderSplit/>
                    {children}
                    <BlueFooter/>
                </>
            )
        }
    }

    //history.push("/mybooklink");

    return (
        <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens}}>
            <Router basename="/facultas">
                <div className="App">
                    <Layout>
                        <Switch>
                            <Route exact path="/" component={SplitPage}/>
                            <Route path="/students/newpwd" component={PasswordGenerated}/>
                            <Route exact path="/students/" component={StartPage}/>
                            <Route path="/students/signup" component={SignupForm}/>
                            <PrivateRoute path="/students/password" component={PasswordPicker}/>
                            <Route path="/students/password-reset" component={PasswordReset}/>
                            <Route path="/students/password-info" component={PasswordInfo}/>
                            <Route path="/students/signup-info" component={SignupInfo}/>
                            <Route path="/students/voucher" component={VoucherForm}/>
                            <Route path="/students/login" component={LoginForm}/>
                            <PrivateRoute path="/students/mybooklink" component={Mybooklink}/>
                            <Route path="/students/error" component={ErrorPage}/>


                            <Route path="/professional/newpwd" component={PasswordGeneratedPraktiker}/>
                            <Route exact path="/professional" component={StartPagePraktiker}/>
                            <Route path="/professional/signup" component={SignupFormPraktiker}/>
                            <PrivateRoute path="/professional/password" component={PasswordPickerPraktiker}/>
                            <Route path="/professional/password-reset" component={PasswordResetPraktiker}/>
                            <Route path="/professional/password-info" component={PasswordInfoPraktiker}/>
                            <Route path="/professional/signup-info" component={SignupInfoPraktiker}/>
                            <Route path="/professional/voucher" component={VoucherFormPraktiker}/>
                            <Route path="/professional/login" component={LoginFormPraktiker}/>
                            <PrivateRoute path="/professional/mybooklink" component={MybooklinkPraktiker}/>
                            <Route path="/professional/error" component={ErrorPagePraktiker}/>

                            <Route path="*" component={SplitPage}/>
                        </Switch>
                    </Layout>
                </div>
            </Router>
        </AuthContext.Provider>
    )
}

export default App
