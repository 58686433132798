 const universityList = [
     "Alpen-Adria-Universität Klagenfurt",
     "Johannes Kepler Universität Linz",
     "Karl-Franzens-Universität Graz",
     "Leopold-Franzens-Universität Innsbruck",
     "Paris-Lodron-Universität Salzburg",
     "Universität Wien",
     "Wirtschaftsuniversität Wien",
     "Medizinische Universität Graz",
     "Medizinische Universität Innsbruck",
     "Medizinische Universität Wien",
     "Technische Universität Graz",
     "Technische Universität Wien",
     "Akademie der bildenden Künste Wien",
     "Universität für angewandte Kunst Wien",
     "Universität für künstlerische und industrielle Gestaltung Linz",
     "Universität für Musik und darstellende Kunst Graz",
     "Universität für Musik und darstellende Kunst Wien",
     "Universität Mozarteum Salzburg",
     "Montanuniversität Leoben",
     "Universität für Bodenkultur Wien",
     "Veterinärmedizinische Universität Wien",
     "Anton Bruckner Privatuniversität",
     "Bertha von Suttner Privatuniversität",
     "Central European University",
     "Danube Private University",
     "Gustav Mahler Privatuniversität für Musik",
     "Jam Music Lab",
     "Karl Landsteiner Privatuniversität für Gesundheitswissenschaften",
     "Katholische Privat-Universität Linz",
     "Modul University Vienna",
     "Musik und Kunst Privatuniversität der Stadt Wien",
     "New Design University",
     "Paracelsus Medizinische Privatuniversität",
     "Private Universität für Gesundheitswissenschaften, Medizinische Informatik und Technik",
     "Privatuniversität Schloss Seeburg",
     "Sigmund Freud Privatuniversität Wien",
     "Universität für Weiterbildung Krems",
     "Webster Vienna Private University"
 ]

export default universityList;