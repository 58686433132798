import React, { Component } from 'react';

import "../styles/BluePage.css"
import {Container, Row, Col, Button} from "react-bootstrap"
import logo from "../images/facultas-logo.svg"

export class StartPage extends Component {

    constructor(props) {
        super(props);

        this.handleClickSignup = this.handleClickSignup.bind(this);
        this.handleClickLogin = this.handleClickLogin.bind(this);
    }

    handleClickSignup(event) {
        event.preventDefault();
        this.props.history.push("/students/signup");
    }

    handleClickLogin(event) {
        event.preventDefault();
        this.props.history.push("/students/login");
    }

    render() {
        return (
            <Container fluid className="BluePage">
                <Row className="fill-page">
                    <Col className="center-contents-row" md={ {span: 4, order: 1 } } xs={ { span: 12, order: 1} }>
                        <Button variant="outline-light" type="submit" onClick={this.handleClickSignup}>
                            REGISTRIEREN
                        </Button>
                    </Col>
                    <Col className="center-contents-row" md={ {span: 4, order: 2 } } xs={ { span: 12, order: 3} }>
                        <img src={logo} alt="Facultas Logo" className="img-logo-large"/>
                    </Col>
                    <Col className="center-contents-row" md={ {span: 4, order: 3 } } xs={ { span: 12, order: 2} }>
                        <Button variant="outline-light" type="submit" onClick={this.handleClickLogin}>
                            EINLOGGEN
                        </Button>
                    </Col>
                </Row>
                <div className="impressum"><a href="https://www.facultas.at/unternehmen/impressum" target="_blank">Impressum</a></div>
            </Container>
        )
    }
}

export default StartPage;