import React, {useState} from "react"
import {useHistory} from "react-router-dom";

import "../styles/Modal.css"
import {Button, Form, Modal} from "react-bootstrap"

import {HOST_URL, VOUCHER_URL} from "../constants"
import {useAuth} from "../authentication/auth"
import {Book} from "react-feather";

export function VoucherModal({isVisible, hide}) {

    const history = useHistory()
    const {authTokens} = useAuth()

    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState('')


    const [responseStatus, setResponseStatus] = useState(0)
    const [responseStatusText, setResponseStatusText] = useState('Da ist was schief gegangen.')

    function closeModal() {
        setCodeError('')
        hide()
    }

    function formValid() {
        let hasError = false

        if(!code || code.trim().length === 0) {
            setCodeError("Bitte einen Code eingeben.")
            hasError = true
        }

        if(hasError) {
            console.log('there are errors')
            return false
        }
        return true
    }

    function postVoucher(event) {
        event.preventDefault()

        if(formValid()) {
            const voucherRequestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + authTokens
                },
                body: JSON.stringify({
                    gutschein: code
                })
            }

            console.log(voucherRequestOptions)

            fetch(HOST_URL + VOUCHER_URL, voucherRequestOptions)
                .then((response) => {
                    if(!response.ok) {
                        console.log('response not ok')
                        setResponseStatus(response.status)
                        setResponseStatusText(response.statusText)
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    closeModal()
                    window.loadFirstBook(window.authTokens);
                })
                .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: "/error",
                        state: {
                            responseStatus: responseStatus,
                            responseStatusText: responseStatusText
                        }
                    })
                    console.log(responseStatus)
                    console.log(responseStatusText)
                })
        }
    }

    return (
        <Modal show={isVisible} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Neues eBook hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-centered">
                    <div>
                        <Book
                            size={110}
                            strokeWidth={0.9}
                            color="#7F7F7F" />
                    </div>
                    <div>
                        <Form.Control
                            className={codeError
                                ? "form-control-modal is-invalid"
                                : "form-control-modal"}
                            type="name"
                            placeholder="CODE EINGEBEN"
                            value={code}
                            onChange={event => {
                                setCode(event.target.value)
                            }} />
                        <div className="error-msg-modal">
                            {codeError}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={closeModal}>
                    SCHLIESSEN
                </Button>
                <Button variant="outline-dark" onClick={postVoucher}>
                    EINLÖSEN
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VoucherModal