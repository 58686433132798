import React, {Component} from "react"

import "../styles/BluePage.css"
import {Container, Row, Col, Button} from "react-bootstrap"

export class PasswordGenerated extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: ''//this.props.location.state.email
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.history.push({
            pathname: "/students/login",
            state: {email: this.state.email}
        })
    }

    render() {
        return (
            <Container fluid className="BluePage">
            <Row className="fill-page" >
            <Col className="center-contents-column">
            <Row className="info-msg">
            Dein neues Password hast du soeben per E-Mail zugesandt bekommen. Bitte prüfe dein Postfach und logge dich mit deinem neuen Passwort ein.
        </Row>
        <Row>
        <Button variant="outline-light"
        type="submit"
        onClick={this.handleClick}>
            EINLOGGEN
            </Button>
            </Row>
            </Col>
            </Row>
            </Container>
    )
    }
}

export default PasswordGenerated