import React, {useState} from "react"
import {Link, Redirect, useHistory} from "react-router-dom";

import "../styles/BluePage.css"
import {Container, Form, Row, Col, Button} from "react-bootstrap"
import {Lock} from "react-feather"

import {EMAIL_REGEX, HOST_URL, LOGIN_URL} from "../constants";
import {useAuth} from "../authentication/auth";

export function LoginForm() {

    const history = useHistory()
    const {authTokens, setAuthTokens} = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [responseStatus, setResponseStatus] = useState(0)
    const [responseStatusText, setResponseStatusText] = useState('Da ist was schief gegangen.')

    function formValid() {
        let hasError = false

        if(!email || email.trim().length === 0) {
            setEmailError("Bitte eine E-Mailadresse eingeben.")
            hasError = true
        }

        else if(!EMAIL_REGEX.test(email)) {
            setEmailError("Bitte eine gültige E-Mailadresse eingeben.")
            hasError = true
        }

        if(!password || password.trim().length === 0) {
            setPasswordError("Bitte Passwort eingeben.")
            hasError = true
        }

        if(hasError) {
            console.log('there are errors')
            return false
        }
        return true
    }

    function postLogin(event) {
        event.preventDefault()

        if(formValid()) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: email,
                    password: password
                })
            }
            console.log('login is being submitted')
            console.log(requestOptions)

           fetch(HOST_URL + LOGIN_URL, requestOptions)
                .then((response) => {
                    if(!response.ok) {
                        console.log('response not ok')
                        setResponseStatus(response.status)
                        setResponseStatusText(response.statusText)
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    return response.json()
                })

               .then((json) => {
                   setAuthTokens(json.token)
                   console.log(json.token)
                   window.authToken = json.token;



               })

               .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: "/students/error",
                        state: {
                            responseStatus: responseStatus,
                            responseStatusText: responseStatusText
                        }
                    })
                })
        }
    }

    if (authTokens) {
        return <Redirect to="/students/mybooklink" />
    }

    return (
        <Container fluid className="BluePage">
            <Row className="fill-page">
                <Col className="icon-custom-position" md={4}>
                    <Lock className="icon-custom" />
                </Col>

                <Col className="center-contents-row" md={4}>
                    <Form className="form-small" onSubmit={postLogin}>
                        <h1 className="page-title">LOGIN</h1>
                        <Form.Group as={Row} controlId="formLoginEmail">
                            <Form.Control
                                name="email"
                                className={emailError
                                    ? "form-control is-invalid"
                                    : "form-control"}
                                type="name"
                                placeholder="E-MAIL"
                                value={email}
                                onChange={event => {
                                    setEmail(event.target.value)
                                    console.log(event.target.name + ' was changed')
                                }} />
                            <div className="error-msg">
                                {emailError}
                            </div>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formLoginPassword">
                                <Form.Control
                                    name="password"
                                    className={passwordError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="name"
                                    placeholder="PASSWORT"
                                    value={password}
                                    onChange={event => {
                                        setPassword(event.target.value)
                                        console.log(event.target.name + ' was changed')
                                    }} />
                                <div className="error-msg">
                                    {passwordError}
                                </div>
                        </Form.Group>

                        <Button
                            type="submit"
                            variant="outline-light"
                            onClick={postLogin}>
                            EINLOGGEN
                        </Button>

                        <div>
                            <Link to="/students/password-reset" className="link-small" >
                                Passwort vergessen?
                            </Link>
                        </div>
                    </Form>
                </Col>

                <Col className="center-contents-row" md={4}>
                </Col>
            </Row>

        </Container>
    )
}

export default LoginForm