import React, {Component} from "react"

import "../styles/BluePage.css"
import {Container, Row, Col, Button} from "react-bootstrap"
import {CheckCircle} from "react-feather"

class SignupInfo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: this.props.location.state.email
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.history.push({
            pathname: "/professional/voucher",
            state: {email: this.state.email}
        })
    }

    render() {
        return (
            <Container fluid className="BluePagePraktiker">
                <Row className="fill-page">
                    <Col className="center-contents-column">
                        <Row>
                            <CheckCircle className="icon-custom" />
                        </Row>
                        <Row className="info-msg">
                            Vielen Dank für Ihre Anmeldung!
                        </Row>
                        <Row className="info-msg">
                            Wir haben Ihnen ein E-Mail mit Ihrem Passwort geschickt.
                        </Row>
                        <Row className="info-msg" style={{paddingBottom: "2rem"}} >
                            Auf der nächsten Seite können Sie sich mit diesem Passwort einloggen.
                        </Row>
                        <Row>
                            <Button variant="outline-light"
                                    type="submit"
                                    onClick={this.handleClick}>
                                WEITER
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default SignupInfo