import React from "react"

import "../styles/Backdrop.css"

function Backdrop(props) {
    return (
        <div className="backdrop" onClick={props.backdropClickHandler}/>
    )
}

export default Backdrop