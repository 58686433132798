import React, {Component} from "react"
import {Link} from 'react-router-dom'

import "../styles/BlueHeaderPraktiker.css"
import Container from "react-bootstrap/Container"

export class BlueHeaderPraktiker extends Component{

    render() {
        return (
            <Container fluid className="BlueHeaderPraktiker">
                <Link to="/" className="page-header-white" style={{color: "white"}}>
                    mybooklink for professionals
                </Link>
            </Container>
        )
    }
}

export default BlueHeaderPraktiker