const studyFieldList = [
    "Recht",
    "Management",
    "Medien & Kommunikation",
    "Pädagogik, Verwaltung & Soziales",
    "Design, Kunst & Musik",
    "Sprachen, Kultur- & Geisteswissenschaften",
    "Gesundheit, Medizin & Sport",
    "Ingenieurwissenschaft & Technik",
    "Informatik & IT",
    "Naturwissenschaften",
    "Umwelt- & Agrarwissenschaften"
]

export default studyFieldList;