import React from "react"

import "../styles/GreyPagePraktiker.css"
import {Container, Row, Col} from "react-bootstrap"
import {useAuth} from "../authentication/auth"

export function MybooklinkPraktiker() {

    const {authTokens} = useAuth()



    return (

        <div className="GreyPage">
        <div className="nobook">
        Kein eBook vorhanden!
        </div>
        </div>

    )
}

export default MybooklinkPraktiker