import React, { Component } from "react"

import "../styles/BluePagePraktiker.css"
import {Container, Form, Col, Row, Button} from "react-bootstrap"
import {Lock} from "react-feather"

import {HOST_URL, PASSWORD_CHANGE_URL} from "../constants"

export class PasswordPickerPraktiker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            password: '',
            passwordConfirmation: '',
            responseStatus: ':(',
            responseStatusText: 'Da ist was schiefgegangen.'
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    formValid() {
        console.log('form is being validated')

        let passwordError = ""
        let passwordConfirmationError = ""

        if(!this.state.password) {
            passwordError = "Bitte ein Passwort eingeben."
        }

        else if(this.state.password.length.trim() < 8 ||
            this.state.password.length.trim() > 32) {
            passwordError = "Länge: mindestes 8/maximal 32 Zeichen."
        }

        if(!this.state.passwordConfirmation) {
            passwordError = "Bitte das Passwort wiederholen."
        }

        if(this.state.password !== this.state.passwordConfirmation ||
            this.state.passwordConfirmation !== this.state.password) {
            passwordError = "Passwörter müssen gleich sein."
            passwordConfirmationError = "Passwörter müssen gleich sein."
        }

        if(passwordError || passwordConfirmationError ) {

            console.log('setting state')

            this.setState({
                passwordError: passwordError,
                passwordConfirmationError: passwordConfirmationError,
            })
            return false
        }
        return true
    }


    handleSubmit(event) {
        event.preventDefault()
        console.log('password is being submitted')

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                password: this.state.password,
                passwordConfirmation: this.state.passwordConfirmation
            })
        }

        console.log(requestOptions)

        if(this.formValid()) {
            fetch(HOST_URL + PASSWORD_CHANGE_URL, requestOptions)
                .then((response) => {
                    if(!response.ok) {
                        this.setState({
                                responseStatus: response.status,
                                responseStatusText: response.statusText
                            }
                        )
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    response.json()
                })

                .then((data) => {
                    console.log('password change successful', data)
                    this.props.history.push({
                        pathname: "/professional/password-info",
                        state: {email: this.state.email}
                    })
                })

                .catch((error) => {
                    console.log(error)
                    this.props.history.push({
                        pathname: "/professional/error",
                        state: {
                            responseStatus: this.state.responseStatus,
                            responseStatusText: this.state.responseStatusText
                        }
                    })
                })
        }
    }

    handleChange(event) {
        const input = event.target
        const value = event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;

        this.setState( {
            [event.target.name]: value
        })

        console.log(input.name + ' was changed')
    }

    render() {
        return (
            <Container fluid className="BluePagePraktiker">
                <Row className="fill-page" >
                    <Col className="icon-custom-position" md={4}>
                        <Lock className="icon-custom"/>
                    </Col>

                    <Col className="center-contents-row" md={4}>
                        <Form onSubmit={this.handleSubmit}>
                            <h1 className="page-title" >PASSWORT ÄNDERN</h1>
                            <Form.Group as={Row} controlId="formChangePwPassword">
                                    <Form.Control
                                        name="password"
                                        className={this.state.passwordError
                                            ? "form-control is-invalid"
                                            : "form-control"}
                                        type="name"
                                        placeholder="PASSWORT"
                                        value={this.state.password}
                                        onChange={this.handleChange}/>
                                    <div className="error-msg">
                                        {this.state.passwordError}
                                    </div>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formChangePwConfirm">
                                <Form.Control
                                    name="passwordConfirmation"
                                    className={this.state.passwordConfirmationError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="name"
                                    placeholder="PASSWORT BESTÄTIGEN"
                                    value={this.state.passwordConfirmation}
                                    onChange={this.handleChange}/>
                                <div className="error-msg">
                                    {this.state.passwordConfirmationError}
                                </div>
                            </Form.Group>

                            <Button
                                type="submit"
                                variant="outline-light"
                                onClick={this.handleSubmit}>
                                PASSWORT ÄNDERN
                            </Button>
                        </Form>
                    </Col>

                    <Col className="center-contents-row" md={4}>
                    </Col>

                </Row>
            </Container>
        )
    }
}

export default PasswordPickerPraktiker