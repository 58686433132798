import React, {useState} from "react"
import {useHistory} from "react-router-dom";

import "../styles/Modal.css"

import {Button, Form, Modal} from "react-bootstrap"

import {HOST_URL, VOUCHER_URL} from "../constants"
import {useAuth} from "../authentication/auth"
import {Book} from "react-feather";

export function UserprofileModal({isVisible, hide, data}) {

    const history = useHistory()
    const {authTokens} = useAuth()

    function closeModal() {
        hide()
    }

    /*
    "vorname": q.profile.vorname,
                        "nachname": q.profile.nachname,
                        "matrikel_nr": q.profile.matrikel_nr,
                        "studienrichtung": q.profile.studienrichtung,
                        "unternehmen": q.profile.unternehmen,
                        "firmenbuchnummer": q.profile.fn,
                        "professional": q.profile.professional
     */

    var st = {width: "100%"};

    /*
     <tr class="hiddenTR"><td>MatrikelNummer:</td>    <td id="prf_matrikelnummer"></td></tr>
                        <tr class="hiddenTR"><td>Studienrichtung:</td>   <td id="prf_studienrichtung"></td></tr>
                        <tr><td>Unternehmen:</td>       <td id="prf_unternehmen"></td></tr>
                        <tr><td>Firmenbuchnummer</td>   <td id="prf_fn"></td></tr>
                        <tr class="hiddenTR"><td>Uni: </td>              <td id="prf_uni"></td></tr>
                        <tr class="hiddenTR"><td>Profi: </td>            <td id="prf_profi">Ja</td></tr>

                        <tr><td>Abos: </td>              <td id="prf_abo">Leseproben : Gratis</td></tr>
     */

    return (
        <Modal show={isVisible} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Userprofil</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-centered">
                    <table style={st}>
                        <tr><td>Vorname: </td>          <td id="prf_vorname"></td></tr>
                        <tr><td>Nachname: </td>         <td id="prf_nachname"></td></tr>
                        <tr><td>Email: </td>            <td id="prf_email"></td></tr>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={closeModal}>
                    SCHLIESSEN
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserprofileModal