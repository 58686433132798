import React, {Component} from 'react'

import '../styles/BlueFooter.css'
import Container from 'react-bootstrap/Container'
import lettering from '../images/facultas-lettering.png'

import {FACULTAS_LINK} from "../constants"

export class BlueFooter extends Component{

    render() {
        return (
            <Container fluid className="BlueFooter">
                <a href={FACULTAS_LINK} target="_blank" rel="noopener noreferrer">
                    <img className="img-logo-type" src={lettering} alt="Facultas Schriftzug" />
                </a>
            </Container>
        )
    }
}

export default BlueFooter