import React, {useState} from 'react'
import {Link, useHistory} from "react-router-dom"

import '../styles/BluePage.css'
import '../styles/Checkbox.css'
import {Container, Form, Row, Col, Button} from 'react-bootstrap'
import {User} from "react-feather"

import {HOST_URL, SIGNUP_URL, EMAIL_REGEX, STUDENT_ID_REGEX} from '../constants'
import universityList from '../data/universityList'
import studyFieldList from '../data/studyFieldList'


export function SignupForm() {
    const history = useHistory()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [emailConfirmation, setEmailConfirmation] = useState('')
    const [studentId, setStudentId] = useState('')
    const [university, setUniversity] = useState('')
    const [studyField, setStudyField] = useState('')
    const [newsletter, setNewsletter] = useState(false)
    const [agb, setAgb] = useState(false)
    const [confirmation, setConfirmation] = useState(false)

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailConfirmationError, setEmailConfirmationError] = useState('')
    const [studentIdError, setStudentIdError] = useState('')
    const [universityError, setUniversityError] = useState('')
    const [studyFieldError, setStudyFieldError] = useState('')
    const [agbError, setAgbError] = useState('')
    const [confirmationError, setConfirmationError] = useState('')

    const [responseStatus, setResponseStatus] = useState(0)
    const [responseStatusText, setResponseStatusText] = useState('Da ist was schief gegangen.')

    function formValid() {
        let hasError = false
        setFirstNameError('')
        setLastNameError('')
        setEmailError('')
        setEmailConfirmationError('')
        setStudentIdError('')
        setUniversityError('')
        setStudyFieldError('')
        setAgbError('')
        setConfirmationError('')

        if(!firstName || firstName.trim().length === 0) {
            setFirstNameError("Bitte einen Vornamen eingeben.")
            hasError = true
        }

        else if(firstName.length < 3) {
            setLastNameError("Länge: mindestens 3 Buchstaben.")
            hasError = true
        }

        if(!lastName || lastName.trim().length === 0) {
            setLastNameError("Bitte einen Nachnamen eingeben.")
            hasError = true
        }

        else if(lastName.length < 3) {
            setLastNameError("Länge: mindestens 3 Buchstaben.")
            hasError = true
        }

        if(!email || email.trim().length === 0) {
            setEmailError("Bitte eine E-Mailadresse eingeben.")
            hasError = true
        }

        else if(!EMAIL_REGEX.test(email)) {
            setEmailError("Bitte eine gültige E-Mailadresse eingeben.")
            hasError = true
        }

        if(!emailConfirmation || emailConfirmation.trim().length === 0) {
            setEmailConfirmationError("Bitte eine E-Mailadresse eingeben.")
            hasError = true
        }

        else if(!EMAIL_REGEX.test(emailConfirmation)) {
            setEmailConfirmationError("Bitte eine gültige E-Mailadresse eingeben.")
            hasError = true
        }

        if(email !== emailConfirmation || emailConfirmation !== email) {
            setEmailError("Adressen müssen gleich sein.")
            setEmailConfirmationError("Adressen müssen gleich sein.")
            hasError = true
        }

        if(!studentId) {
            setStudentIdError("Bitte eine Matrikelnummer eingeben.")
            hasError = true
        }

        else if(studentId.trim().length < 7) {
            setStudentIdError("Länge: mindestens 7 Stellen.")
            hasError = true
        }

        else if(!STUDENT_ID_REGEX.test(studentId)) {
            setStudentIdError("Bitte nur Ziffern (0-9) eingeben.")
            hasError = true
        }

        if(!university) {
            setUniversityError("Bitte eine Universität auswählen.")
            hasError = true
        }

        if(!studyField) {
            setStudyFieldError("Bitte eine Studienrichtung auswählen.")
            hasError = true
        }

        if(!agb) {
            setAgbError("Bitte bestätigen.")
            hasError = true
        }

        if(!confirmation) {
            setConfirmationError("Bitte bestätigen.")
            hasError = true
        }

        if(hasError) {
            console.log('there are errors')
            return false
        }
        return true
    }

    function postLogin(event) {
        event.preventDefault()

        if(formValid()) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    profile: {
                        vorname: firstName,
                        nachname: lastName,
                        uni: university,
                        matrikel_nr: studentId,
                        studienrichtung: studyField,
                        newsletter: newsletter,
                        agb: agb,
                        bestaetigung: confirmation
                    }
                })
            }
            console.log(requestOptions)

            fetch(HOST_URL + SIGNUP_URL, requestOptions)
                .then((response) => {
                    if(!response.ok) {
                        console.log('response not ok')
                        setResponseStatus(response.status)
                        setResponseStatusText(response.statusText)
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    response.json()
                })

                .then(() => {
                    history.push({
                        pathname: "/students/signup-info",
                        state: {email: email}
                    })
                })

                .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: "/students/error",
                        state: {
                            responseStatus: responseStatus,
                            responseStatusText: responseStatusText
                        }
                    })
                })
        }
    }

    return (
        <Container fluid className="BluePage">
            <Row>
                <Col className="icon-custom-position" lg={3}>
                    <User className="icon-custom-large-form" />
                </Col>

                <Col lg={6}>
                    <Form className="form-large" onSubmit={postLogin} noValidate>
                        <h1 className="page-title">ACCOUNT ANLEGEN</h1>
                        <Form.Group as={Row} controlId="formSignupFirstName">
                            <Form.Label column sm={4}>Vorname</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="firstName"
                                    className={firstNameError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="name"
                                    value={firstName}
                                    onChange={event => {setFirstName(event.target.value)}} />
                                <div className="error-msg">
                                    {firstNameError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupLastName">
                            <Form.Label column sm={4}>Nachname</Form.Label>
                            <Col sm={{ span:6, offset:0 }}>
                                <Form.Control
                                    name="lastName"
                                    className={lastNameError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="name"
                                    value={lastName}
                                    onChange={event => {setLastName(event.target.value)}} />
                                <div className="error-msg">
                                    {lastNameError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupEmail">
                            <Form.Label column sm={4}>E-Mail</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="email"
                                    className={emailError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="email"
                                    value={email}
                                    onChange={event => {setEmail(event.target.value)}} />
                                <div className="error-msg">
                                    {emailError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupEmailConfirmation">
                            <Form.Label column sm={4}>E-Mail bestätigen</Form.Label>
                            <Col sm={{ span:6, offset:2 }}>
                                <Form.Control
                                    name="emailConfirmation"
                                    className={emailConfirmationError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="email"
                                    value={emailConfirmation}
                                    onChange={event => {setEmailConfirmation(event.target.value)}}/>
                                <div className="error-msg">
                                    {emailConfirmationError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupStudentId">
                            <Form.Label column sm={4}>Matrikelnummer</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="studentId"
                                    className={studentIdError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="id"
                                    value={studentId}
                                    onChange={event => {setStudentId(event.target.value)}} />
                                <div className="error-msg">
                                    {studentIdError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupUniversity">
                            <Form.Label column sm={4}>Uni</Form.Label>
                            <Col sm={{ span:6, offset:0 }}>
                                <Form.Control
                                    name="university"
                                    className={universityError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    as="select"
                                    value={university}
                                    onChange={event => {setUniversity(event.target.value)}}>
                                    <option value="" disabled>Universität auswählen...</option>
                                    {universityList.map(uni => {
                                        return (
                                            <option>{uni}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupStudyField">
                            <Form.Label column sm={4}>Studienrichtung</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="studyField"
                                    className={studyFieldError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    as="select"
                                    value={studyField}
                                    onChange={event => {setStudyField(event.target.value)}} >
                                    <option value="" disabled>Studienrichtung auswählen...</option>
                                    {studyFieldList.map(field => {
                                        return (
                                            <option>{field}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupNewsletter">
                            <Col sm={{ span:11, offset:1 }}>
                                <label className="round-checkbox">
                                    <input
                                        name="newsletter"
                                        type="checkbox"
                                        checked={newsletter}
                                        onChange={event => {setNewsletter(event.target.checked)}} />
                                    <span className="checkmark"> </span>
                                    <div className="form-check">
                                        Newsletter abonnieren (Abmeldung ist jederzeit möglich)
                                    </div>
                                </label>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupAgb">
                            <Col sm={{ span:11, offset:1 }}>
                                <label className="round-checkbox">
                                    <input
                                        name="agb"
                                        type="checkbox"
                                        checked={agb}
                                        onChange={event => {setAgb(event.target.checked)}} />
                                    <span className="checkmark"> </span>
                                    <div
                                        className={agbError
                                            ? "form-check form-check-error"
                                            : "form-check"}>
                                        Ich habe die <a href="/facultas-mybooklink-AGB.pdf" target="_blank">AGB</a> gelesen und bin einverstanden. Die <a href="/facultas-mybooklink-DSE.pdf" target="_blank">DSE</a> habe ich zur Kenntnis genommen. *
                                    </div>
                                </label>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupConfirmation">
                            <Col sm={{ span:11, offset:1 }}>
                                <label className="round-checkbox">
                                    <input
                                        name="confirmation"
                                        type="checkbox"
                                        checked={confirmation}
                                        onChange={event => {setConfirmation(event.target.checked)}} />
                                    <span className="checkmark"> </span>
                                    <div
                                        className={confirmationError
                                            ? "form-check form-check-error"
                                            : "form-check"}>
                                        Ich bestätige, diesen Zugang ausschließlich im Rahmen
                                        meiner Studien- bzw. Lehrtätigkeit zu nutzen. *
                                    </div>
                                </label>
                            </Col>
                        </Form.Group>

                        <Button
                            type="submit"
                            variant="outline-light"
                            onClick={postLogin}>
                            WEITER
                        </Button>

                        <div>
                            <Link to="/login" className="link-small" >
                                Schon registriert?
                            </Link>
                        </div>
                    </Form>
                </Col>

                <br/><br/><br/>
                <Col lg={12} style={{"color":"white"}}><br/><br/><br/><br/>
                    Sie sind Profi? <a href="/facultas/professional">Hier</a> geht's zur Registrierung für mybooklink for professionals.
                </Col>

            </Row>

        </Container>
    )
}

export default SignupForm