import React, {useState} from 'react'
import {useHistory} from "react-router-dom";

import '../styles/BluePagePraktiker.css'
import {Container, Form, Row, Col, Button} from "react-bootstrap"
import {BookOpen} from "react-feather"

import {HOST_URL, LOGIN_URL, VOUCHER_URL} from "../constants"
import {useAuth} from "../authentication/auth"

export function VoucherFormPraktiker(props) {

    const history = useHistory()
    const {authTokens, setAuthTokens} = useAuth()

    const [email] = useState(props.location.state.email)

    const [password, setPassword] = useState('')
    const [code2, setCode] = useState('')
    const [codeChecked, setCodeChecked] = useState(true)

    const [passwordError, setPasswordError] = useState('')
    const [codeError, setCodeError] = useState('')

    const [responseStatus, setResponseStatus] = useState(0)
    const [responseStatusText, setResponseStatusText] = useState('Da ist was schief gegangen.')

    function formValid() {
        let hasError = false

        if(!password || password.trim().length === 0) {
            setPasswordError("Bitte das Passwort eingeben.")
            hasError = true
        }

        if(codeChecked && (!code2 || code2.trim().length === 0)) {
            setCodeError("Bitte einen Code eingeben.")
            hasError = true
        }

        if(hasError) {
            console.log('there are errors')
            return false
        }
        return true
    }

    function postVoucher(event) {
        event.preventDefault()

        console.log(code2);

        if(formValid()) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: email,
                    password: password
                })
            }
            console.log('login is being submitted')
            console.log(requestOptions)
            console.log(code2);

            window.c2 = code2;

            fetch(HOST_URL + LOGIN_URL, requestOptions)
                .then((response) => {
                    if(!response.ok) {
                        console.log('response not ok')
                        setResponseStatus(response.status)
                        setResponseStatusText(response.statusText)
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    return response.json()
                })

                .then((json) => {
                    setAuthTokens(json.token)
                    console.log(json.token)
                    console.log(code2);
                })

                .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: "/professional/error",
                        state: {
                            responseStatus: responseStatus,
                            responseStatusText: responseStatusText
                        }
                    })
                })
        }
    }

    if(authTokens) {
        if(codeChecked){
            console.log('submitting code')

            const voucherRequestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + authTokens
                },
                body: JSON.stringify({
                    gutschein: window.c2
                })
            }

            console.log(voucherRequestOptions)

            fetch(HOST_URL + VOUCHER_URL, voucherRequestOptions)
                .then((response) => {
                    if(!response.ok) {
                        console.log('response not ok')
                        setResponseStatus(response.status)
                        setResponseStatusText(response.statusText)
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    history.push({
                        pathname: "/professional/mybooklink"
                    })
                    console.log('new book added')
                })
                .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: "/professional/error",
                        state: {
                            responseStatus: responseStatus,
                            responseStatusText: responseStatusText
                        }
                    })
                    console.log(responseStatus)
                    console.log(responseStatusText)
                })
        }
        else {
            history.push({
                pathname: "/professional/mybooklink"
            })
        }
    }

    return (
        <Container fluid className="BluePagePraktiker">
            <Row className="fill-page" >
                <Col className="icon-custom-position" lg={4}>
                    <BookOpen className="icon-custom" />
                </Col>

                <Col className="center-contents-row" lg={4}>
                    <Form className="form-small" onSubmit={postVoucher}>
                        <h1 className="page-title" >ACCOUNT ANLEGEN</h1>
                        <Form.Group as={Row} controlId="formVoucherPassword">
                            <Form.Control
                                className={passwordError
                                    ? "form-control is-invalid"
                                    : "form-control"}
                                type="name"
                                placeholder="PASSWORT"
                                value={password}
                                onChange={event => {
                                    setPassword(event.target.value)
                                }} />
                            <div className="error-msg">
                                {passwordError}
                            </div>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formVoucherCodeChecked">
                            <Col className="toggle-box">
                                <Form.Check type="switch"
                                            label=""
                                            value="true"
                                            checked={codeChecked}
                                            onChange={event => {
                                                setCodeChecked(event.target.checked)
                                            }}  />
                            </Col>
                            <Col>
                                <div className={codeChecked
                                    ? "toggle-text-active"
                                    : "toggle-text-inactive"}>
                                    Ja, ich habe einen Code.
                                </div>
                                <div className={codeChecked
                                    ? "toggle-text-inactive"
                                    : "toggle-text-active"}>
                                    Nein, ich habe noch keinen Code.
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formVoucherCode">
                            <Form.Control
                                className={codeError
                                    ? "form-control is-invalid"
                                    : "form-control"}
                                type="name"
                                placeholder="CODE EINGEBEN"
                                value={code2}
                                onChange={event => {
                                    setCode(event.target.value)
                                    console.log(event.target.value)
                                }}  onKeyUp={event => {
        setCode(event.target.value)
        console.log(event.target.value)
    }}  />
                            <div className="error-msg">
                                {codeError}
                            </div>
                        </Form.Group>

                        <Button
                            type="submit"
                            variant="outline-light"
                            onClick={postVoucher}>
                            ABSENDEN
                        </Button>
                    </Form>
                </Col>

                <Col className="center-contents-row" lg={4}>
                </Col>
            </Row>
        </Container>
    )
}

export default VoucherFormPraktiker