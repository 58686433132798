import React, {useState} from 'react'
import {Link, useHistory} from "react-router-dom"

import '../styles/BluePagePraktiker.css'
import '../styles/Checkbox.css'
import {Container, Form, Row, Col, Button} from 'react-bootstrap'
import {User} from "react-feather"

import {HOST_URL, SIGNUP_URL, EMAIL_REGEX, STUDENT_ID_REGEX} from '../constants'
import universityList from '../data/universityList'
import branchenList from '../data/branchenList'
import studyFieldList from '../data/studyFieldList'


export function SignupFormPraktiker() {
    const history = useHistory()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [emailConfirmation, setEmailConfirmation] = useState('')
    const [Uid, setUid] = useState('')
    const [Fn, setFn] = useState('')
    const [Unternehmen, setUnternehmen] = useState('')
    const [university, setUniversity] = useState('')
    const [studyField, setStudyField] = useState('')
    const [newsletter, setNewsletter] = useState(false)
    const [agb, setAgb] = useState(false)
    const [confirmation, setConfirmation] = useState(false)

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailConfirmationError, setEmailConfirmationError] = useState('')
    const [studentIdError, setStudentIdError] = useState('')
    const [universityError, setUniversityError] = useState('')
    const [agbError, setAgbError] = useState('')
    const [confirmationError, setConfirmationError] = useState('')

    const [responseStatus, setResponseStatus] = useState(0)
    const [responseStatusText, setResponseStatusText] = useState('Da ist was schief gegangen.')

    function formValid() {
        let hasError = false
        setFirstNameError('')
        setLastNameError('')
        setEmailError('')
        setEmailConfirmationError('')
        setUniversityError('')
        setAgbError('')
        setConfirmationError('')

        if(!firstName || firstName.trim().length === 0) {
            setFirstNameError("Bitte einen Vornamen eingeben.")
            hasError = true
        }

        else if(firstName.length < 3) {
            setLastNameError("Länge: mindestens 3 Buchstaben.")
            hasError = true
        }

        if(!lastName || lastName.trim().length === 0) {
            setLastNameError("Bitte einen Nachnamen eingeben.")
            hasError = true
        }

        else if(lastName.length < 3) {
            setLastNameError("Länge: mindestens 3 Buchstaben.")
            hasError = true
        }

        if(!email || email.trim().length === 0) {
            setEmailError("Bitte eine E-Mailadresse eingeben.")
            hasError = true
        }

        else if(!EMAIL_REGEX.test(email)) {
            setEmailError("Bitte eine gültige E-Mailadresse eingeben.")
            hasError = true
        }

        if(!emailConfirmation || emailConfirmation.trim().length === 0) {
            setEmailConfirmationError("Bitte eine E-Mailadresse eingeben.")
            hasError = true
        }

        else if(!EMAIL_REGEX.test(emailConfirmation)) {
            setEmailConfirmationError("Bitte eine gültige E-Mailadresse eingeben.")
            hasError = true
        }

        if(email !== emailConfirmation || emailConfirmation !== email) {
            setEmailError("Adressen müssen gleich sein.")
            setEmailConfirmationError("Adressen müssen gleich sein.")
            hasError = true
        }


        if(!agb) {
            setAgbError("Bitte bestätigen.")
            hasError = true
        }



        if(hasError) {
            console.log('there are errors')
            return false
        }
        return true
    }

    function postLogin2(event) {
        event.preventDefault()

        if(formValid()) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    profile: {
                        vorname: firstName,
                        nachname: lastName,
                        uni: university,
                        uid:Uid,
                        fn: Fn,
                        unternehmen: Unternehmen,
                        newsletter: newsletter,
                        agb: agb,
                        bestaetigung: confirmation,
                        professional: true,
                        test: true,
                    }
                })
            }
            console.log(requestOptions)

            fetch(HOST_URL + SIGNUP_URL, requestOptions)
                .then((response) => {
                    if(!response.ok) {
                        console.log('response not ok')
                        setResponseStatus(response.status)
                        setResponseStatusText(response.statusText)
                        throw Error(response.status + ': ' + response.statusText)
                    }
                    response.json()
                })

                .then(() => {
                    history.push({
                        pathname: "/professional/signup-info",
                        state: {email: email}
                    })
                })

                .catch((error) => {
                    console.log(error)
                    history.push({
                        pathname: "/professional/error",
                        state: {
                            responseStatus: responseStatus,
                            responseStatusText: responseStatusText
                        }
                    })
                })
        }
    }

    return (
        <Container fluid className="BluePagePraktiker">
            <Row>
                <Col className="icon-custom-position" lg={3}>
                    <User className="icon-custom-large-form" />
                </Col>

                <Col lg={6}>
                    <Form className="form-large" onSubmit={postLogin2} noValidate>
                        <h1 className="page-title">ACCOUNT ANLEGEN</h1>
                        <Form.Group as={Row} controlId="formSignupFirstName">
                            <Form.Label column sm={4}>Vorname *</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="firstName"
                                    className={firstNameError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="name"
                                    value={firstName}
                                    onChange={event => {setFirstName(event.target.value)}} />
                                <div className="error-msg">
                                    {firstNameError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupLastName">
                            <Form.Label column sm={4}>Nachname *</Form.Label>
                            <Col sm={{ span:6, offset:0 }}>
                                <Form.Control
                                    name="lastName"
                                    className={lastNameError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="name"
                                    value={lastName}
                                    onChange={event => {setLastName(event.target.value)}} />
                                <div className="error-msg">
                                    {lastNameError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupEmail">
                            <Form.Label column sm={4}>E-Mail *</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="email"
                                    className={emailError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="email"
                                    value={email}
                                    onChange={event => {setEmail(event.target.value)}} />
                                <div className="error-msg">
                                    {emailError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupEmailConfirmation">
                            <Form.Label column sm={4}>E-Mail bestätigen *</Form.Label>
                            <Col sm={{ span:6, offset:2 }}>
                                <Form.Control
                                    name="emailConfirmation"
                                    className={emailConfirmationError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    type="email"
                                    value={emailConfirmation}
                                    onChange={event => {setEmailConfirmation(event.target.value)}}/>
                                <div className="error-msg">
                                    {emailConfirmationError}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupUniversity">
                            <Form.Label column sm={4}>Branche</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="university"
                                    className={universityError
                                        ? "form-control is-invalid"
                                        : "form-control"}
                                    as="select"
                                    value={university}
                                    onChange={event => {setUniversity(event.target.value)}}>
                                    <option value="" disabled>Branche auswählen...</option>
                                    {branchenList.map(branche => {
                                        return (
                                            <option>{branche}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupUnternehmen">
                            <Form.Label column sm={4}>Unternehmen</Form.Label>
                            <Col sm={{ span:6, offset:0 }}>
                                <Form.Control
                                    name="Unternehmen"
                                    className="form-control"
                                    type="id"
                                    value={Unternehmen}
                                    onChange={event => {setUnternehmen(event.target.value)}} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupUid">
                            <Form.Label column sm={4}>UID</Form.Label>
                            <Col sm={{ span:6, offset:1 }}>
                                <Form.Control
                                    name="uid"
                                    className="form-control"
                                    type="id"
                                    value={Uid}
                                    onChange={event => {setUid(event.target.value)}} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupFn">
                            <Form.Label column sm={4}>Firmenbuchnummer</Form.Label>
                            <Col sm={{ span:6, offset:2 }}>
                                <Form.Control
                                    name="fn"
                                    className="form-control"
                                    type="id"
                                    value={Fn}
                                    onChange={event => {setFn(event.target.value)}} />
                            </Col>
                        </Form.Group>



                        <Form.Group as={Row} controlId="formSignupNewsletter">
                            <Col sm={{ span:11, offset:1 }}>
                                <label className="round-checkbox">
                                    <input
                                        name="newsletter"
                                        type="checkbox"
                                        checked={newsletter}
                                        onChange={event => {setNewsletter(event.target.checked)}} />
                                    <span className="checkmark"> </span>
                                    <div className="form-check">
                                        Newsletter abonnieren (Abmeldung ist jederzeit möglich)
                                    </div>
                                </label>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSignupAgb">
                            <Col sm={{ span:11, offset:1 }}>
                                <label className="round-checkbox">
                                    <input
                                        name="agb"
                                        type="checkbox"
                                        checked={agb}
                                        onChange={event => {setAgb(event.target.checked)}} />
                                    <span className="checkmark"> </span>
                                    <div
                                        className={agbError
                                            ? "form-check form-check-error"
                                            : "form-check"}>
                                        Ich habe die <a href="/facultas-mybooklink-AGB.pdf" target="_blank">AGB</a> gelesen und bin einverstanden. Die <a href="/facultas-mybooklink-DSE.pdf" target="_blank">DSE</a> habe ich zur Kenntnis genommen. *
                                    </div>
                                </label>
                            </Col>
                        </Form.Group>

                        <Button
                            type="submit"
                            variant="outline-light"
                            onClick={postLogin2}>
                            WEITER
                        </Button>

                        <div>
                            <Link to="/professional/login" className="link-small" >
                                Schon registriert?
                            </Link>
                        </div>
                    </Form>
                </Col>

                <br/><br/><br/>
                <Col lg={12} style={{"color":"white"}}><br/><br/><br/><br/>
                    Sie studieren? <a href="/facultas/students">Hier</a> geht's zur Registrierung für mybooklink for students.
                </Col>

            </Row>

        </Container>
    )
}

export default SignupFormPraktiker