import React, {Component} from "react"

import "../styles/BluePagePraktiker.css"
import {Container, Row, Col, Button} from "react-bootstrap"

export class ErrorPagePraktiker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            responseStatus: this.props.responseStatus,
            responseStatusText: this.props.location.responseStatusText
        }

        this.handleClickHome = this.handleClickHome.bind(this)
    }

    handleClickHome() {
        this.props.history.push({
            pathname: "/professional"
        })
    }

    render() {
        return (
            <Container fluid className="BluePagePraktiker">
                <Row className="fill-page">
                    <Col className="center-contents-column">
                        <Row className="error-msg-sadface">
                            :(
                        </Row>
                        <Row className="error-msg-api">
                            {this.state.responseStatusText}
                        </Row>
                        <Row className="error-msg-api">

                        </Row>
                        <Row>
                            <Button variant="outline-light"
                                    type="submit"
                                    onClick={this.handleClickHome}>
                                ZURÜCK ZUM START
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ErrorPagePraktiker