import React, {useEffect, useState} from "react"

import "../styles/SideDrawer.css"
import {BookOpen, PlusCircle, X} from "react-feather"

import VoucherModal from "./VoucherModal"
import {BOOKS_URL, HOST_URL} from "../constants"
import {useAuth} from "../authentication/auth"

export function SideDrawer(props) {

    const [modalState, setModalState] = useState(false)
    const [books, setBooks] = useState([])
    const {authTokens} = useAuth()



    function showModal() {
        setModalState(true)
    }

    function hideModal() {
        setModalState(false)
    }


    function openBook(book)
    {
        window.fname = book.title;
        window.loadFile(book.eBook_pdf.replace("127.0.0.1","api.books-dev.lereto.com"));
        window.fname = book.title;
	window.currentBook = book;
        console.log("OPEN BOOK CALLED");
    }

    window.loadBooks = function()
    {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + authTokens
            }
        }

        console.log('REQUEST OPTIONS')
        console.log(requestOptions)

        fetch(HOST_URL + BOOKS_URL, requestOptions)
            .then((response) => {
                console.log('RESPONSE')
                console.log(response)
                if(!response.ok) {
                    console.log('response not ok')
                    throw Error(response.status + ': ' + response.statusText)
                }
                return response.json()
            })
            .then((json) => {
                setBooks(json.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }



    useEffect(() => {
        window.loadBooks()}, [authTokens])

    return (
        <div className="side-drawer">
            <div className="side-icon-wrapper" style={{justifyContent: "right"}}>
                <X className="close" onClick={props.drawerClickHandler}/>
            </div>
            <div className="book-title-side-drawer" style={{wordWrap: "break-word", margin: "20px"}}>
            </div>
            <ul>
                {books.map((book) =>
                    <li key={book.id} >
                        <div className="side-book" onClick={() => openBook(book)}>
                        <div className="side-icon-wrapper">
                            <BookOpen
                                className="side-icon-large"
                                size={60}
                                color="#7F7F7F" />
                        </div>
                        <div className="book-title-side-drawer">
                           {book.title}
                        </div>
                        </div>
                        <hr/>
                    </li>)}
                <li>
                    <div className="side-icon-wrapper" >
                        <PlusCircle className="side-icon-small" onClick={window.openGutscheinModal} />
                    </div>
                </li>
            </ul>


        </div>


    )
}

export default SideDrawer
