import React, {Component} from "react"
import {Link} from 'react-router-dom'

import "../styles/BlueHeader.css"
import Container from "react-bootstrap/Container"

export class BlueHeader extends Component{

    render() {
        return (
            <Container fluid className="BlueHeader">
                <Link to="/" className="page-header-white" style={{"color":"white"}}>
                    mybooklink for students
                </Link>
            </Container>
        )
    }
}

export default BlueHeader